/* Custom CSS START */
.no-color {
  color: #dfe4fd;
}

html {
  scroll-behavior: smooth;
}

body {
  position: relative;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif !important;
}

.font-opensans {
  font-family: Open Sans, sans-serif !important;
}

.ls-1 {
  letter-spacing: 0.5px;
}

.p-100 {
  padding-top: 80px !important;
}

.home-bg {
  height: 100vh;
  /* background: url("../public/img/carousel-1.jpg")*/
  background-size: cover;
  background-repeat: no-repeat;
}

.home-heading {
  letter-spacing: 1px;
  line-height: 1.3;
}

.home-heading.mobile {
  font-size: 1.2rem;
}

.lead-small {
  font-size: 1rem;
}

.bounce {
  animation-duration: 2s;
  animation-iteration-count: infinite;
  animation-name: bounce;
  animation-timing-function: ease;
}

/* navbar toggle bar animation */
.navbar-toggler {
  width: 20px;
  height: 15px;
  position: relative;
  transition: 0.5s ease-in-out;
}

.navbar-toggler,
.navbar-toggler:active,
.navbar-toggler:focus,
.navbar-toggler-icon:focus {
  outline: none;
  box-shadow: none;
  border: 0;
}

.navbar-toggler span {
  margin: 0;
  padding: 0;
}

.toggler-icon {
  display: block;
  position: absolute;
  height: 1px;
  width: 100%;
  background: var(--light);
  border-radius: 1px;
  opacity: 1;
  left: 0;
  transform: rotate(0deg);
  transition: 0.35s ease-in-out;
}

.middle-bar {
  margin-top: 0;
}

/* When nav is clicked */
.navbar-toggler .start-bar {
  margin-top: 0;
  transform: rotate(45deg);
}

.navbar-toggler .middle-bar {
  opacity: 0;
  filter: alpha(opacity=0);
}

.navbar-toggler .end-bar {
  margin-top: 0;
  transform: rotate(-45deg);
}

/* when the nav is collapsed */
.navbar-toggler.collapsed .start-bar {
  margin-top: -15px;
  transform: rotate(0deg);
}

.navbar-toggler.collapsed .middle-bar {
  opacity: 1;
  filter: alpha(opacity=100);
}

.navbar-toggler.collapsed .end-bar {
  margin-top: 15px;
  transform: rotate(0deg);
}

/* change accordion-btn-icon color to white */
.accordion {
  --bs-accordion-btn-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='white'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
  --bs-accordion-btn-active-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='white'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
}

/* .dropdown-menu {
  animation: 1s slideUp;
}

@keyframes slideUp {
  from {
    opacity: 0;
    transform: translateY(100%);
  }

  to {
    opacity: 1;
    transform: translateY(0%);
  }
} */


@keyframes bounce {
  0% {
    transform: scale(1, 1) translateY(0);
  }

  10% {
    transform: scale(1, 1) translateY(0);
  }

  30% {
    transform: scale(1, 1) translateY(-20px);
  }

  50% {
    transform: scale(1, 1) translateY(0);
  }

  57% {
    transform: scale(1, 1) translateY(-5px);
  }

  64% {
    transform: scale(1, 1) translateY(0);
  }

  100% {
    transform: scale(1, 1) translateY(0);
  }
}

.fs-1 {
  font-size: 0.95rem !important;
  text-align: justify;
}

.fs-2 {
  font-size: 1.25rem !important;
  font-weight: 500 !important;
  letter-spacing: 0.5px;
}

.text-truncate-2-line {
  max-width: 100%;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.text-truncate-3-line {
  max-width: 100%;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}

.btn:active {
  border: none !important;
}

.service-card {
  transition: 1s ease-in-out;
}

.service-card>.card-body>.card-text {
  font-size: 1.15rem;
  font-weight: 100;
}

.service-card:hover {
  background: linear-gradient(144deg, rgba(13, 110, 253, 1) 0%, rgba(0, 0, 0, 1) 100%);
}

.bg-black-transparent {
  background-color: rgb(0 0 0 / 70%);
  border-radius: 1rem;
}

.card-title>img {
  width: 80px;
  height: 80px;
}


/*
*
*
*
*
*
*
*
*/
/* Custom CSS END */


:root {
  --primary: #355efc;
  --secondary: #e93c05;
  --tertiary: #555555;
  --light: #dfe4fd;
  --dark: #030222;
}

.back-to-top {
  position: fixed;
  right: 30px;
  bottom: 30px;
  z-index: 99;
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

@-webkit-keyframes fadeOut {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

@-webkit-keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.fadeOut {
  -webkit-animation-name: fadeOut;
  animation-name: fadeOut;
}

.fadeIn {
  -webkit-animation-name: fadeIn;
  animation-name: fadeIn;
}

h1,
.h1,
h2,
.h2,
.fw-bold {
  font-weight: 700 !important;
}

h3,
.h3,
h4,
.h4,
.fw-medium {
  font-weight: 600 !important;
}

h5,
.h5,
h6,
.h6,
.fw-semi-bold {
  font-weight: 500 !important;
}

/*** Spinner ***/
#spinner {
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.5s ease-out, visibility 0s linear 0.5s;
  z-index: 99999;
}

#spinner.show {
  transition: opacity 0.5s ease-out, visibility 0s linear 0s;
  visibility: visible;
  opacity: 1;
}

/*** Button ***/
.btn {
  transition: 0.5s;
  font-weight: 500;
}

.btn-primary,
.btn-outline-primary:hover {
  color: var(--light);
}

.btn-square {
  width: 38px;
  height: 38px;
}

.btn-sm-square {
  width: 32px;
  height: 32px;
}

.btn-lg-square {
  width: 48px;
  height: 48px;
}

.btn-square,
.btn-sm-square,
.btn-lg-square {
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: normal;
}

/*** Navbar ***/
.fixed-top {
  transition: 0.5s;
}

.top-bar {
  height: 45px;
  border-bottom: 1px solid rgba(53, 94, 252, 0.07);
  color: #dfe4fd;
}

.navbar .dropdown-toggle::after {
  border: none;
  content: "\f107";
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  vertical-align: middle;
  margin-left: 8px;
}

.navbar .navbar-nav .nav-link {
  padding: 25px 15px;
  color: var(--light);
  font-weight: 500;
  outline: none;
}

.navbar .navbar-nav .nav-link:hover,
.navbar .navbar-nav .nav-link.active {
  color: var(--primary) !important;
}

@media (max-width: 991.98px) {
  .navbar .navbar-nav {
    margin-top: 10px;
    border-top: 1px solid rgba(0, 0, 0, 0.07);
    background: var(--dark);
  }

  .navbar .navbar-nav .nav-link {
    padding: 10px 0;
    color: var(--light);
  }
}

.dropdown-item {
  padding: 0.5rem 1rem !important;
  color: var(--light);
  transition: 0.3s;
}

.dropdown-item::before {
  position: relative;
  content: "\f105";
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  margin-right: 10px;
}

.dropdown-item:hover {
  background-color: black;
  color: var(--primary);
}

@media (max-width: 991px) {
  .dropdown-menu {
    top: 100%;
    transform: rotateX(0deg);
    transform-origin: 0% 0%;
    transition: 0.5s;
    width: 100%;
  }

  .nav-item:active .dropdown-menu {
    transform: rotateX(90deg) !important;
    transition: 0.5s !important;
  }
}

@media (min-width: 992px) {
  .navbar .nav-item .dropdown-menu {
    display: block;
    visibility: hidden;
    top: 100%;
    transform: rotateX(-75deg);
    transform-origin: 0% 0%;
    transition: 0.5s;
    opacity: 0;
    /* 0 */
    width: max-content;
  }

  .navbar .nav-item:hover .dropdown-menu {
    transform: rotateX(0deg);
    visibility: visible;
    transition: 0.5s;
    opacity: 1;
  }
}

/*** Header ***/
.carousel-caption {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: start;
  z-index: 1;
}

.carousel-control-prev,
.carousel-control-next {
  width: 3rem;
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
  width: 3rem;
  height: 3rem;
  background-color: var(--primary);
  border: 10px solid var(--primary);
}

.carousel-control-prev-icon {
  border-radius: 0 3rem 3rem 0;
}

.carousel-control-next-icon {
  border-radius: 3rem 0 0 3rem;
}

@media (max-width: 768px) {
  #header-carousel .carousel-item {
    position: relative;
    min-height: 450px;
  }

  #header-carousel .carousel-item img {
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.page-header {
  padding-top: 12rem;
  padding-bottom: 6rem;
  /* background: url("../public/img/header.jpg") top left no-repeat; */
  background-size: cover;
}

.page-header .breadcrumb-item,
.page-header .breadcrumb-item a {
  font-weight: 500;
}

.page-header .breadcrumb-item+.breadcrumb-item::before {
  color: var(--tertiary);
}

/*** Facts ***/
.facts {
  background: linear-gradient(rgba(53, 94, 252, 0.95), rgba(53, 94, 252, 0.95)),
    url("../public/img/bg.png");
}

/*** Callback ***/
.callback {
  position: relative;
}

.callback::before {
  position: absolute;
  content: "";
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: linear-gradient(rgba(0, 0, 0, 1), rgba(0, 0, 0, 1)),
    url("../public/img/bg.png");
  z-index: -1;
}

/*** Feature ***/
.feature .feature-box,
.feature .feature-box * {
  transition: 0.5s;
}

.feature .feature-box:hover {
  background: var(--primary);
  border-color: var(--primary) !important;
}

.feature .feature-box:hover * {
  color: #ffffff !important;
}

/*** Service ***/
.service .nav .nav-link {
  transition: 0.5s;
}

.service .nav .nav-link.active {
  border-color: var(--primary) !important;
  background: var(--primary);
}

.service .nav .nav-link.active h5 {
  color: #ffffff !important;
}

.service .nav .nav-link.active h5 i {
  color: #ffffff !important;
}

/*** Project ***/
.project-item,
.project-item .project-img {
  position: relative;
}

.project-item .project-img a {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: rgba(255, 255, 255, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  opacity: 0;
  transition: 0.5s;
}

.project-item:hover .project-img a {
  opacity: 1;
}

.project-item .project-title {
  position: absolute;
  top: 3rem;
  right: 0;
  bottom: 0;
  left: 3rem;
  border: 1px solid var(--light);
  border-radius: 8px;
  display: flex;
  align-items: flex-end;
  padding: 18px;
  z-index: -1;
  transition: 0.5s;
}

.project-item:hover .project-title {
  background: var(--primary);
  border-color: var(--primary);
}

.project-item .project-title h4 {
  transition: 0.5s;
}

.project-item:hover .project-title h4 {
  color: #ffffff;
}

.project-carousel .owl-nav {
  margin-top: 25px;
  display: flex;
  justify-content: center;
}

.project-carousel .owl-nav .owl-prev,
.project-carousel .owl-nav .owl-next {
  margin: 0 12px;
  width: 45px;
  height: 45px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--primary);
  background: var(--light);
  border-radius: 45px;
  font-size: 22px;
  transition: 0.5s;
}

.project-carousel .owl-nav .owl-prev:hover,
.project-carousel .owl-nav .owl-next:hover {
  background: var(--primary);
  color: var(--light);
}

/*** Team ***/
.team-item {
  position: relative;
  padding: 4rem 0;
}

.team-item img {
  position: relative;
  z-index: 2;
}

.team-item .team-text {
  position: absolute;
  top: 0;
  right: 3rem;
  bottom: 0;
  left: 3rem;
  padding: 15px;
  border: 1px solid var(--light);
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  transition: 0.5s;
  z-index: 1;
}

.team-item:hover .team-text {
  background: var(--primary);
  border-color: var(--primary);
}

.team-item .team-text h4 {
  transition: 0.5s;
}

.team-item:hover .team-text h4 {
  color: #ffffff;
}

.team-item .team-social .btn {
  background: var(--light);
  color: var(--primary);
}

.team-item:hover .team-social .btn {
  background: #ffffff;
}

.team-item .team-social .btn:hover {
  background: var(--primary);
  color: var(--light);
}

/*** Testimonial ***/
.testimonial-item {
  position: relative;
  text-align: center;
  padding-top: 30px;
}

.testimonial-item .testimonial-text {
  position: relative;
  text-align: center;
}

.testimonial-item .testimonial-text .btn-square {
  position: absolute;
  width: 60px;
  height: 60px;
  top: -30px;
  left: 50%;
  transform: translateX(-50%);
}

.testimonial-item .testimonial-text::before {
  position: absolute;
  content: "";
  bottom: -60px;
  left: 50%;
  transform: translateX(-50%);
  border: 30px solid;
  border-color: var(--light) transparent transparent transparent;
}

.testimonial-item .testimonial-text::after {
  position: absolute;
  content: "";
  bottom: -59px;
  left: 50%;
  transform: translateX(-50%);
  border: 30px solid;
  border-color: #ffffff transparent transparent transparent;
}

.testimonial-carousel .owl-item img {
  margin: 0 auto;
  width: 100px;
  height: 100px;
}

.testimonial-carousel .owl-dots {
  margin-top: 25px;
  display: flex;
  align-items: flex-end;
  justify-content: center;
}

.testimonial-carousel .owl-dot {
  position: relative;
  display: inline-block;
  margin: 0 5px;
  width: 30px;
  height: 30px;
  border: 1px solid var(--light);
  border-radius: 30px;
  transition: 0.5s;
}

.testimonial-carousel .owl-dot::after {
  position: absolute;
  content: "";
  width: 16px;
  height: 16px;
  top: 6px;
  left: 6px;
  border-radius: 16px;
  background: var(--light);
  transition: 0.5s;
}

.testimonial-carousel .owl-dot.active {
  border-color: var(--primary);
}

.testimonial-carousel .owl-dot.active::after {
  background: var(--primary);
}

/*** Footer ***/
.footer p {
  color: var(--light);
}

.footer .btn.btn-link {
  display: block;
  margin-bottom: 5px;
  padding: 0;
  text-align: left;
  color: var(--light);
  font-weight: normal;
  text-transform: capitalize;
  transition: 0.3s;
  text-decoration: none;
}

.footer .btn.btn-link::before {
  position: relative;
  content: "\f105";
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  margin-right: 10px;
}

.footer .btn.btn-link:hover {
  color: var(--primary);
  letter-spacing: 0.5px;
  box-shadow: none;
}

.copyright {
  color: var(--light);
  background: #000b1c;
}

.copyright a {

  color: var(--light);
  font-weight: normal;
  text-transform: capitalize;
  transition: 0.3s;
}

.copyright a:hover {
  color: var(--primary);
  box-shadow: none;
}

.copyright .link {
  padding: 0 2rem;
  border: none;
}

@media (min-width: 576px) {
  .copyright .link {
    border-right: solid 1px;
  }





}

@media(max-width:575px) {
  .card-title>img {
    width: 60px;
    height: 60px;
  }

  .card-body>ul {
    padding-left: 0;
    padding-right: 0;
  }

  .card-text {
    font-size: 1rem !important;
  }
}